<template>
  <campaign-form
    action="create"
    :loading="loading"
    :updating="creating"
    :error-messages="errorMessages"
    :handle-submit="handleFormSubmit"
    :handle-discard="handleDiscard"
  />
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { computed, ref, getCurrentInstance } from '@vue/composition-api'

import CampaignForm from './campaign-form/CampaignForm.vue'

export default {
  components: {
    CampaignForm,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const campaignForm = ref(null)
    const errorMessages = ref({})

    const loading = ref(false)
    const creating = computed(() => vm.$store.getters['campaigns/creating'])

    const handleFormSubmit = formData =>
      vm.$store
        .dispatch('campaigns/create', formData)
        .then(res => {
          if (res?.status === 200 && res?.data?.data?.campaign) {
            const campaignId = res.data.data.campaign.id

            vm.$router.replace({
              name: 'campaign-view',
              params: {
                id: campaignId,
              },
            })
          }
        })
        .catch(err => {
          if (err?.data?.errors) {
            errorMessages.value = err.data.errors
          }
        })

    const handleDiscard = () => vm.$router.replace({ name: 'campaign-list' })

    return {
      loading,
      creating,

      campaignForm,

      errorMessages,

      handleFormSubmit,
      handleDiscard,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
